/* ::::::::: */
/* aktionCard */
/* ::::::::: */


.aktionCard-container{
    display: flex;
    flex-wrap: wrap; 
    flex-direction: row;
    align-items:flex-start; /* vertical align */
    justify-content: center;
    min-width: 80%;
    gap: 2em; /* space between items */
    margin: 1em auto;
    padding: 2em 0 0.5em 0;
}

.aktionCard{
    width: 130px;
    height: 100px;
    border-radius: 0.6em;
    color: var(--black);
    font-size: 1.4em;
    font-family: 'poppins-semibold';
    border: solid 2px var(--Grass);
    transition: transform .2s; /* Animation */
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1em;
}
.aktionCard:hover{
    transform: scale(1.1);
}
.aktionCard-text2{
    font-size: 0.6em;
   
    align-self: center;
}
.aktionCard-text{
    font-size: 0.6em;
    position: relative;
    top: -3em;
    align-self: center;
}
.actionCard-icon-container{
    position: absolute !important;
    width: 50px;
    height: 50px;
    margin-left: -160px;
    margin-top: -90px;
    overflow: hidden;
}

.actionCard-icon-container-onpage{
    position: relative;
    width: 100px;
    height: 100px;
}
.aktionCard-nr-onpage{
    text-align: center;
    width: 18px;
    font-size: 0.6em;
    position: absolute;
    color: var(--black);
    background-color: var(--white);
    height: 18px;
    border-radius: 14px;
    top: 70px;
    right: 17px;
    padding: 0.2em;
}

.aktionCard-icon{
    position: relative;
    }

.aktionCard-nr{
    width: 14px;
    font-size: 0.45em;
    position: absolute;
    color: var(--black);
    background-color: var(--white);
    height: 14px;
    border-radius: 16px;
    top:34px;
    right: 10px;
}
