.workplaceHeader{
    background-color: var(--Stone20);
    border-radius: 0.2em;
    display: flex;
    padding: 0.4em;
    
}
.workplaceHeader-icon{
    width: 40px;
    height: 40px;
    padding-left: 1em;
    
}
.workplaceHeader-text{
    align-self: center;
    text-align: center;
    width: 100%;
}
