.breadcrumb-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    padding: 0 0.5em 0.2em;
}

.single-breadcrumb {
    display: flex;
}

.single-breadcrumb>* {
    padding: 0 0.2em;
    font-size: 0.7em;
}