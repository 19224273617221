/* Header */
.header {
    background-color: var(--white);
    position: sticky;
    min-height: 15vh;
    height: auto;
    top: 0;
    width: 100%;
    border-bottom: solid thin var(--Stone);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.01), 0 6px 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 50;
  }
  .headerLogoContainer {
    position: relative;
    float: left;
    padding-left: 1em;
    padding-top: 0.5vmin;
    /* width: 12vmin; */
  }
  .gnp-logo{
    height: 12vh;
  }
  
  .headerLinksContainer {
    position: relative;
    float: right;
    margin-right: 1em;
  }
  
  
  .icon-container {
    margin-top: 0em;
    width: 20px;
  }
  
  .home-icon {
    height: 15px;
    width: 15px;
    top: 3px;
    position: relative;
  }
  
  .headerLink {
    color: var(--black);
    display: inline;
    list-style-type: none;
    font-size: 0.75em;
  }
  
  .link:link {
    text-decoration: none;
  }
  
  .link:hover {
    color: var(--Organge);
  }
  
  .link:active {
    /*  color: var(--AmgenBlue); */
    text-decoration: underline;
  }
  
  .link:visited {
    color: var(--black);
  }
  
  .headerLinkSeparator {
    color: var(--black);
    padding: 0 0.5em;
    font-size: 0.7em;
  }
  
  @media screen and (max-width: 560px) {
  
   
    .headerLinksContainer {
      width: 50%;
      display: flex;
      flex-direction: column;
      line-height: 0.6em;
      text-align: right;
      padding-top: 0.5em;
    } 
  
    .headerLinkSeparator {
      visibility: hidden;
    }
  
    .headerLink {
      font-size: 0.7em;
    }
  }