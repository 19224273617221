/* Footer */
.footer {
    background-color: var(--white);
    height: var(--footer-height);
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;
    border-top: solid thin var(--Graphite);
    padding: 0.5vw;
}

.footer-flex-container {
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.footerInner {
    margin: 0 auto;
    padding: 0.4em 0;
    display: flex;
    align-items: center;
}

.footerLinkText {
    top: 0;
    margin: 0;
    color: var(--Graphite) !important;
    font-size: 0.7em;
    display: inline;
    list-style-type: none;
    padding: 0.3em 0.5em 0.3em 0.5em;
}

.footerLink:link {
    text-decoration: none;
}

.footerLink:hover {
    text-decoration: underline;
}

.footerLinkSeparator {
    color: var(--Graphite);
    margin: 0;
    font-size: 0.7em;
}

.version {
    font-size: 0.6em;
    color: var(--Stone);
    padding-left: 1em;
}

.sponsor-logo {
    height: 2vmax;
    padding-right: 1em;
}

@media screen and (max-width: 680px) {
    .footer-flex-container {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;
    }

    .footerLinkText {
        padding: 0 0.2em 1em;
    }

    .footerLinkSeparator {
        font-size: 0.6em;
        visibility: hidden;
    }

    .version {
        padding: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0.2em;
    }

    .sponsor-logo {
        padding: 0;
        position: absolute;
        bottom: 0;
        right: 0;
        margin: 0.2em 0.6em;
    }

    .footerInner {
        flex-wrap: wrap;
        font: unset;
        justify-content: center;
    }
}