.linearSection-container {
    border-radius: 0.6em;
    text-align: center;
    margin-bottom: 1em;
    padding-bottom: 1em;
}

.linearSection-imageContainer {
    width: 100%;
    height: 10em;
    overflow: hidden;
    border-radius: 1em 1em 0 0;
}

.linearSection-image {
    border-radius: 1em 1em 0 0;
    width: 100%;
}

.linearSection-textContainer {
    margin: 0 auto;
    text-align: left;
    padding: 0 4em 2em 0;
    background-color: var(--Stone20);
}
.linearContent-text{
    font-size: 0.8em;
    font-weight: normal;
    font-family: 'poppins-regular';
}

.bg-white {
    background-color: var(--white);
}

.linearSection-title-nr {
    width: 20px;
    font-size: 0.9em;
    font-weight: bold;
    text-align: left;
}

.inline {
    display: flex;
}

.linearSection-title {
    width: 380px;
    font-size: 0.9em;
    font-weight: bold;
    text-align: left;
}

.linearSection-headline1 {
    font-size: 1.4em;
    font-family: 'poppins-semibold';
    text-align: left;
    padding: 1em 0;
}

.linearSection-headline1-nr {
    min-width: 45px;
    margin-left: 1em;
}

.linearSection-list {
    font-size: 0.9em;
    font-weight: 300;
    padding-top: 0.5em;
    list-style-type: disc;
}

.linearSection-benefit {
    display: flex;
    justify-content: left;
    align-items: center;
}

.linearSection-benefit-headline {
    padding-left: 0.5em;
}

.linearSection-benefit-image {
    height: 30px;
}

.linearSection-legende {
    font-size: small;
}
.linearSection-legende-button {
    text-align: center;
    padding: 2em;
    font-size: small;
    max-width: 200px;
}

.linearContent-table-text-header {
    color: var(--AmgenBlue);
    padding-right: 1em;
    padding-bottom: 1em;
    font-family: 'poppins-semibold';
}


/* @media screen and (max-width: 480px) {
    .linearSection-container {
        width: 100%;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .linearSection-container {
        width: 320px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .linearSection-container {
        width: 360px;
    }
}

@media screen and (min-width: 1025px) {
    .linearSection-container {
        width: 40%;
    }
} */
.pb1 {
    padding-bottom: 1em;
}