/* Mobile */
@media screen and (max-width: 480px) {
    .page {
      margin: 0 0 5em 0;
      padding: 0.2em;
    }
    .list-text {
      width: 90%;
    }
    .aktionLink-container{
        margin-left: 20px;
    }
    .linearSection-textContainer{
      padding: 0 1em 0.5em 0;
      
    }
    .actionlink-container{
      margin-left: 1.2em;
    }
    .linearSection-headline1-nr {
      min-width: 30px;
      margin-left: 0.5em;
    }
    .linearSection-headline1 {
      font-size: 1.2em;
      font-family: 'poppins-semibold';
      text-align: left;
      padding: 0.2em 0;
  }
  }
  /* Tablet */
  @media screen and (min-width: 481px) and (max-width: 1024px) {
    .page {
      margin: 1em auto 2em auto;
      padding: 1em;
    }
  
    .headline1 {
      font-size: 1.4em;
    }
    .aktionLink-container{
        margin-left: 20px;
    }
  }
  /* Desktop */
  @media screen and (min-width: 1025px) {
    .page {
      margin: 1em auto 2em auto;
      max-width: 900px;
      padding: 1em;
    }
  
    .headline1 {
      font-size: 1.6em;
    }
  }