.login-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/AdobeStock_526097568.jpeg');
    background-size: cover;
}
.login-container-logo{
    width: 150px;
    margin-left: auto;
    margin-right: auto;
}
.login-container form {
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to top, var(--Slate) 0%,var(--white20) 90%);
    padding: 2em 2em 4em 2em;
}
.login-legende{
    color: var(--white);
    font-size: 0.8em;
    padding: 2em 0;
    text-align: center;
}
.login-legende-link{
    text-decoration: underline;
    color: var(--white);
}
/* input */
.input-container {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
    
}
.login-input-label{
    color: var(--white);
    font-size: 0.8em;
}
.login-input-button{
    color: var(--white);
    font-size: 1em;
    min-width: 300px;
    border: solid white 1px;
    border-radius: 0.25rem;
    padding: 0.5rem;
    box-sizing: border-box;
    background-color: var(--AmgenBlue);
    margin-top: 1.2em;
}
.input-container input {
    font-size: 1em;
    min-width: 300px;
    border: solid white 1px;
    border-radius: 0.25rem;
    padding: 0.5rem;
    box-sizing: border-box;
    background-color: var(--Slate20);
    color: var(--white);
}

/* login-button */
.input-container input[type="submit"] {
    margin-top: 1.5rem;
    background-color: white;
}

/* error-message */
.login-error-message {
    color: red;
    padding: 1rem 0;
}