/* Padding */
.pb1{
    padding-bottom: 1em;
}
.pb2{
    padding-bottom: 2em;
}
.pt1{
    padding-top: 1em;
}
.pt2{
    padding-top: 2%;
}
.padding-headline1{
    padding: 1em 0.5em;
}
/* Colors */

.bg-sky{
    background-color: var(--Sky);
}
.bg-sea{
    background-color: var(--Sea);
}
.bg-orange{
    background-color: var(--Orange);
}
.bg-grass{
    background-color: var(--Grass);
}
.bg-burgundy{
    background-color: var(--Burgundy);
}

.hidden{
    visibility: hidden;
}
