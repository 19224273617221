/* ::::::::: */
/* motivCard */
/* ::::::::: */
.navLink{
    text-decoration: none;
    color: black;
    cursor: pointer;
}

.motivCard-container{
    display: flex;
    flex-wrap: wrap; 
    flex-direction: row;
    align-items:flex-start; /* vertical align */
    justify-content: center;
    min-width: 80%;
    gap: 2em; /* space between items */
    margin: 1em auto;
    padding: 2em 0 0.5em 0;
}
.motivCard{
    width: 200px;
    height: 160px;
    border-radius: 0.6em;
    display: flex;
   /*  flex-direction: column;
    text-align: center; */
    text-align: center;
    color: var(--black);
    font-size: 1.4em;
    font-family: 'poppins-semibold';
    border: solid 2px var(--Orange);
    transition: transform .2s; /* Animation */
}
.motivCard:hover{
    transform: scale(1.1);
}
.motivCard-text{
    position: absolute;
    width: 170px;
    margin: 0 1em;
    font-size: 0.7em;
    align-self: center;
}
.motivCard-icon{
    height: 50px;
    padding: 0.5em 0;
    position: relative;
    left: -20px;
    top:-30px;
}
.opacity{
    opacity: 0.5;
    pointer-events: none;
}