.readMoreSection-container {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.readMoreSection {
    background-color: var(--Stone20);
    margin: auto;
    border-radius: 1em;
}

.readMoreSection-image {
    border-radius: 1em 1em 0 0;
    width: 100%;
    height: 6rem;
    object-fit: cover;
}

.readMoreSection-contents {
    padding: 0 1em;
}

.readMoreSection-read-more {
    display: block;
    opacity: 0;
    height: 0;
    transition: all 0.5s;
}

.readMoreSection-read-more--open {
    display: block;
    opacity: 1;
    height: auto;
    transition: all 0.6s;
}

/* Button */
.read-more-btn {
    font-size: 0.8em;
    position: relative;
    display: flex;
    justify-content: flex-end;
    transition: all 0.5s;
    margin-right: 2em;
    cursor: pointer;
}


.read-more-icon{
    width: 20px;
    rotate: 90deg;
    margin-left: 0.5em;
}
.read-more-icon-open{
    width: 20px;
    rotate: 270deg;
    margin-left: 0.5em;
}


/* White section */
.steps-section-white{
    padding: 1em;
    background-color: var(--white);
    border-radius: 0.6em;
    border: var(--Sky) 2px solid;
    height: auto;
    margin-bottom: 1em;
    padding-bottom: 1em;
}