/* ::::::::: */
/* zielCard */
/* ::::::::: */
.zielCard-container{
    display: flex;
    flex-wrap: wrap; 
    flex-direction: row;
    align-items:flex-start; /* vertical align */
    justify-content: center;
    min-width: 80%;
    gap: 2em; /* space between items */
    margin: 1em auto;
    padding: 2em 0 0.5em 0;
}
.zielCard{
    width: 180px;
    height: 120px;
    border-radius: 0.6em;
    display: flex;
    /* flex-direction: column;
    align-self: center; */
    text-align: center;
    color: var(--black);
    font-size: 1.4em;
    font-family: 'poppins-semibold';
    border: solid 2px var(--Red);
    transition: transform .2s; /* Animation */
}
.zielCard:hover{
    transform: scale(1.1);
}
.zielCard-text{
    position: absolute;
    width: 150px;
    margin: 0 1em;
    font-size: 0.6em;
    color: var(--Red);
    align-self: center;
}
.zielCard-icon{
    height: 50px;
    padding: 0.5em 0;
    position: relative;
    left: -20px;
    top:-30px;
}