@media screen and (max-width: 480px) {
    .mainCard-container {
        width: 96%;
    }
    .colorCard-container{
        flex-wrap: wrap;
        padding:1em;
        margin:1em !important;
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .mainCard-container {
        width: 480px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
    .mainCard-container {
        width: 360px;
    }
}

@media screen and (min-width: 1025px) {
    .mainCard-container {
        max-width: 46%;
    }
}

.mainCard-container {
    background-color: var(--Stone20);
    border-radius: 0.6em;
    height: auto;
    text-align: center;
    margin-bottom: 1em;
    padding-bottom: 1em;
    transition: transform .2s; /* Animation */
}

.mainCard-imageContainer {
    border-radius: 0.6em 0.6em 0 0;
    overflow: hidden;
    width: 100%;
    height: 120px;
}

.mainCard-textContainer {
    width: 90%;
    min-height: 86px;
    border-top: solid thin;
    margin: 0 auto;
    margin-top: 0.5em;
    padding-top: 0.5em;
    text-align: left;
}

.mainCard-image {
    overflow: hidden;
    width: 100%;
}

.mainCard-title-nr {
    width: 20px;
    font-size: 0.9em;
    font-weight: bold;
    font-family: 'poppins-semibold';
    text-align: left;
}

.inline {
    display: flex;
}

.mainCard-title {
    width: 380px;
    font-size: 0.9em;
    font-family: 'poppins-semibold';
    text-align: left;
}

.mainCard-text {
    font-size: 0.9em;
    font-family: 'poppins-regular';
    padding-top: 0.5em;
}

.mainCard-arrow {
    width: 22px;
    height: 22px;
    float: right;
    bottom: 1em;
}
.mainCard-container:hover{
    transform: scale(1.05);
}

/* MainCardSub */
.mainCardSub-container {
    background-color: var(--white);
    border-radius: 0.6em;
    border: var(--Sky) 2px solid;
    height: auto;
    margin-bottom: 1em;
    padding-bottom: 1em;
    transition: transform .2s; /* Animation */
}
.mainCardSub-container:hover{
    transform: scale(1.05);
}
.mainCardSub-textContainer {
    width: 90%;
    margin: 0 auto;
    margin-top: 0.5em;
    padding-top: 0.5em;
    text-align: left;
    padding-bottom: 1em;
}
.mainCardSub-title-nr {
    min-width: 30px;
    font-size: 0.9em;
    font-weight: bold;
    font-family: 'poppins-semibold';
    text-align: left;
    padding-right: 0.5em;
}
.mainCardSub-title{
    font-size: 0.9em;
    font-family: 'poppins-semibold';
    text-align: left;

}
.mainCardSub-arrow {
    width: 22px;
    height: 22px;
    float: right;
    bottom: 1em;
    position: relative;
}