.custom-link:hover {
    cursor: pointer;
}

.custom-link[active='true'] {
    color: var(--AmgenBlue);
    text-decoration: underline;
}

footer .custom-link[active='true'] {
    color: var(--Graphite);
    text-decoration: underline;
}