@font-face {
  font-family: "poppins-regular";
  src: url(./fonts/AMG_TYP_Poppins-Regular.ttf);
}

@font-face {
  font-family: "poppins-semibold";
  src: url(./fonts/AMG_TYP_Poppins-SemiBold.ttf);
}

body {
  margin: 0;
  font-family: "poppins-regular";
}

html {
  font-size: 16px;
}
th{
  font-weight: normal !important;
}


/* ::::::::::::::: */
/* Page */
/* ::::::::::::::: */
.page {
  background-color: var(--white);
}

.headline1 {
  font-family: 'poppins-semibold';
}

.headline-aktion {
  display: grid;
  align-content: center;
  padding-left: 1em;
}

.headline2 {
  font-family: 'poppins-semibold';
}

.headline3 {
  font-size: 1.1em;
  padding: 1em 0 0 0;
}

.headline4 {
  font-family: 'poppins-semibold';
}

.kapitel-text {
  font-family: 'poppins-semibold';
  font-size: 0.7em;
  padding: 1em 0;
}

.explain-text {
  font-size: 0.7em;
}

.center {
  text-align: center;
}

.flex-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

ul {
  list-style: none;
  /* Remove default bullets */
  padding-left: 1em;
}

ul li::before {
  content: "\2022";
  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: var(--AmgenBlue);
  /* Change the color */
  font-weight: bold;
  /* If you want it to be bold */
  font-size: 1em;
  display: inline-block;
  /* Needed to add space between the bullet and the text */
  width: 1em;
  /* Also needed for space (tweak if needed) */
  margin-left: -1em;
  /* Also needed for space (tweak if needed) */
}

li {
  padding-bottom: 1em;
}

.text-container-grey-round {
  background-color: var(--Stone20);
  border-radius: 1em;
  padding: 2em;
  margin-top: 2em;
}

.readMore{
  overflow: scroll;
  border-bottom: solid thin var(--Stone);
  transition: height 0.6s; /* Animation */
}
.closed{
  height: 150px;
}
.open{
  height: 950px;
}
.readMore-button{
  font-size: 0.6em;
  color: var(--Stone);
  float: right;
  cursor: pointer;
}

/* GRID */

.box {
  box-sizing: border-box;
}

.list-bullet {
  width: 20px;float: left;
}

.list-text {
  width: 70%;
  float: left;
  /* background-color: antiquewhite; */
}

.list-link {
  width: 25%;
  min-width: 200px;
  float: left;
  border: solid thin;
}

.max-width-20 {
  max-width: 20px;
}

.min-width-120 {
  min-width: 200px;
}
/* MOBILE */
/* @media only screen and (max-width: 600px) {
  .page{
    padding: 0 1em;
  }
  .list-bullet {
    visibility: hidden;
  }
  
  .list-text {
    width: 100%;
    float: left;
  }
  
  .list-link {
    width: 100%;
    float: left;
  }

} */


@media only screen and (min-width: 768px) {

  /* For desktop: */
  .col-1 {
    width: 8.33%;
  }

  .col-2 {
    width: 16.66%;
  }

  .col-3 {
    width: 25%;
  }

  .col-4 {
    width: 33.33%;
  }

  .col-5 {
    width: 41.66%;
  }

  .col-6 {
    width: 50%;
  }

  .col-7 {
    width: 58.33%;
  }

  .col-8 {
    width: 66.66%;
  }

  .col-9 {
    width: 75%;
  }

  .col-10 {
    width: 83.33%;
  }

  .col-11 {
    width: 91.66%;
  }

  .col-12 {
    width: 100%;
  }
}

/* All elements where className begins with "col-" */
[class*="col-"] {
  float: left;
  /* padding: 0.5em; */
  /* border: thin solid red; */
  box-sizing: border-box;
  width: 100%;
}

.row::after {
  content: "";
  clear: both;
  display: table;
}


.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 10px;
}

.grid-item-2 {
  justify-self: flex-start;
}

.grid-item-3 {
  justify-self: end;
}

/* Downloads */
.downloads-text {
  text-decoration: none;
  color: var(--AmgenBlue);
  cursor: pointer;
}

.downloads-pdf-icon {
  height: 20px;
}


