/* ::::::::: */
/* WorkplaceCard */
/* ::::::::: */
.workplaceCard-container{
    display: flex;
    flex-wrap: wrap; 
    flex-direction: row;
    align-items:flex-start; /* vertical align */
    justify-content: center;
    min-width: 60%;
    gap: 1em; /* space between items */
    margin: 1em auto;
    padding: 2em 0;
}
.workplaceCard{
    width: 8em;
    height: 5em;
    border-radius: 0.6em;
    display: flex;
    flex-direction: column;
    transition: transform .2s; /* Animation */
    text-align: center;
    color: var(--white);
    font-size: 1.4em;
    font-family: 'poppins-semibold';
    position: relative;
}
.workplaceCard-text{
    font-size: 0.6em;
}
.workplaceCard-icon{
    height: 30px;
    padding: 0.5em 0;
}
.workplaceCard:hover{
    transform: scale(1.1);
    color: var(--white);
}