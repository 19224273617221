/* ::::::::: */
/* ColorCard */
/* ::::::::: */
.colorCard-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 1em;
    margin: 1em auto;
    padding: 2em 0;
}

.colorCard {
    min-width: 6em;
    max-width: 15em;
    height: 6em;
    border-radius: 0.6em;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-size: 1.2em;
    font-family: 'poppins-semibold';
    transition: transform .2s;
    /* Animation */
    padding: 0.5em;
    text-align: center;
    cursor: pointer;
}

.colorCard-small {
    height: 4em;
    font-size: 0.9em;
    padding: 0 1em;
}

.colorCard-mid {
    min-width: 6em;
    height: 3em;
    font-size: 1em;
    padding: 0.5em;
    line-height: 1.2em;
}

.active {
    opacity: 1;
}

/* JA/NEIN */
.planerButton {
    border-radius: 0.6em;
    border: 4px solid;
    min-width: 6em;
    height: 3em;
    font-size: 1.0em;
    color: var(--Sky);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'poppins-semibold';
    cursor: pointer;
    transition: transform .2s; /* Animation */
}
.planerButton:hover {
    transform: scale(1.05);
}
.button-yes {
    border: 4px solid var(--Sky);
    color: var(--Sky);
}
.button-yes-active {
    color: var(--white);
    background-color: var(--Sky);
} 

.button-yes:hover {
    color: var(--white);
    background-color: var(--Sky);
    transform: scale(1.05);
}

.button-no {
    border: 4px solid var(--Orange);
    color: var(--Orange);
}
.button-no-active {
    color: var(--white);
    background-color: var(--Orange);
    
}
.button-no:hover {
    color: var(--white);
    background-color: var(--Orange);
}


.colorCard:hover {
    transform: scale(1.1);
    color: var(--white);
    opacity: 1;
}

.colorCardMini-container {
    width: 30px;
    height: 10px;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 1px;
}

.colorCardMini {
    width: 10px;
    height: 10px;
    padding: 0;
    margin: 0;
    border-radius: 2px;
}

.question {
    text-align: center;
    padding: 0.4em 1em;
    border-radius: 0.3em;
    font-size: 1.4em;
    background-color: var(--Stone20);
    margin-bottom: 1em;
}

.answers-container {
    display: flex;
    justify-content: center;
    min-width: 60%;
    gap: 1em;
    /* space between items */
    margin-bottom: 2em;
    padding: 1em 0;
}