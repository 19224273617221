/* Colors */
:root {
    /* Primary Colors */
    --AmgenBlue: #0063C3;
    --white: #fff;
    --white20: rgb(255, 255, 255, 0.4);
    --black: #000;
    /* Secondary Colors */
    --Sky: #00BCE4; 
    --Sea: #7EE0DD; 
    --Grass: #2CC84D; 
  
    /* Tertiary Colors */
    --Yellow: #FEDD00; 
    --Orange: #FF6720; 
    --Red: #E01029; 
    --Burgundy: #840B55; 
    /* Tertiary Colors - Neutrals */
    --Sand:  rgba(179, 169, 135, 1.0); 
    --Sand80:  rgba(179, 169, 135, 0.8); 
    --Sand60:  rgba(179, 169, 135, 0.6); 
    --Sand40:  rgba(179, 169, 135, 0.4); 
    --Sand20:  rgba(179, 169, 135, 0.2); 
  
    --Slate: rgb(136, 153, 170, 1.0);
    --Slate80: rgb(136, 153, 170, 0.8);
    --Slate60: rgb(136, 153, 170, 0.6);
    --Slate40: rgb(136, 153, 170, 0.4);
    --Slate20: rgb(136, 153, 170, 0.2);
  
    --Stone:  rgb(173, 173, 173); 
    --Stone80: rgb(173, 173, 173, 0.8);
    --Stone60: rgb(173, 173, 173, 0.6);
    --Stone40: rgb(173, 173, 173, 0.4);
    --Stone20: rgb(173, 173, 173, 0.2);
    --Graphite: #605D75;
  }