/* ::::::::: */
/* actionsLink - Link to Aktionen*/
/* ::::::::: */
.actionsLink-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
   /* align-items: flex-start; */
    align-items: center;
    /* vertical align */
    justify-content: center;
    min-width: 80%;
    gap: 2em;
    /* space between items */
    margin: 1em auto;
    padding: 2em 0;
    transition: transform .2s; /* Animation */
}
.actionsLink-container:hover{
    transform: scale(1.03);
}

.actionsLink {
    width: 7em;
    height: 7em;
    border-radius: 0.6em;
    display: flex;
    flex-direction: column;

    text-align: center;
    color: var(--black);
    font-size: 1.4em;
    font-family: 'poppins-semibold';
    border: solid 2px var(--Orange)
}

.actionsLink-text {
    font-size: 0.9em;
    text-align: right;
    line-height: 1em;
}

.actionsLink-icon {
    height: 20px;
    padding-left: 1em;
}
.actionsLink-inline-icon {
    height: 20px;
    padding-left: 0.3em;
    margin-bottom: -4px;
    margin-right: 0.4em;
}

/* AKTION Bullet */
.aktion-row{
    display: flex;
    align-items: flex-end;
}
.frame{
    border: thin red solid;
}
/* ::::::::: */
/* aktionlink - single aktionlink component*/
/* ::::::::: */

.aktionLink-container {
    width: 200px;
    display: flex;
    justify-content:space-between; 
    align-items: flex-end;
    padding: 0.4em 0;
}
.aktionLink-container2 {
    width: 180px;
    display: flex;
    justify-content:space-between; 
 
}

.aktionLink-text {
    font-size: 0.8em;
    color: var(--AmgenBlue);
}

.aktionLink-icon {
    width: 20px;
    height: 20px;
}

/* ::::::::: */
/* aktionlink - central Link/ Shortcut*/
/* ::::::::: */

.direktLink-container{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 2em 0 2em;
    transition: transform .2s; /* Animation */
}
.direktLink-container:hover{
    transform: scale(1.03);
}

.direktLink-text{
    font-size: 0.8em;
}
.direktLink-icon{
    width: 2em;
    height: 2em;
    margin-left: 1em;
}


/* ::::::::: */
/* BackToTop - central Link/ Shortcut*/
/* ::::::::: */
.backToTop-container{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 2em 0 2em;
    transition: transform .2s; /* Animation */
}

.backToTop-text {
    font-size: 0.8em;
    color: var(--AmgenBlue);
    padding-right: 2em;
}